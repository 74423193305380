import React from 'react';
import { Link } from 'react-router-dom';

import Utils from '../../../../helpers/Utils';

import './styles.css';

const Nav = ({ label, to, items, finalidade }) => {
    return (
        <div className="footer-box">
            <h2>{label}</h2>
            <ul>
                {items.map((item, index) => (
                    <li key={index}>
                        <Link 
                            to={ to ? {pathname: item.to} : `/busca/aluguel/${Utils.transliterate(item.name)}/fortaleza/${finalidade}`} 
                            target={item.target}
                            rel="noopener noreferrer"
                        > 
                            {Utils.capitalize(item.name)}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Nav;