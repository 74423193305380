import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import ReactBnbGallery from 'react-bnb-gallery';

import Utils from '../../../helpers/Utils';

import leftArrow from '../../../assets/images/icons/left-arrow.svg';
import photography from '../../../assets/images/icons/photography.svg';

import 'react-bnb-gallery/dist/style.css';
import './styles.css';

export default class ContentGallery extends Component {
    state = {
        isOpen: false,
        numberPhoto: 0,
        photos: [],
        responsive:{
            0: {
                items: 1
            },
            500: {
                items: 2
            },
            768: {
                items: 3
            }
        }
    }

    componentDidMount(){
        this.handleLoadImagesForCarousel();
    }

    handleLoadImagesForCarousel = () => {
        const images = [];
        this.props.imagens.forEach(img => images.push(img.imagem))
        this.setState({
            photos: images
        })
    }

    handleGallety = (index) => {
        this.setState({
            isOpen: true,
            numberPhoto: index
        })
    }

    render(){
        // const goBack = (this.props.codigoLabel !== "" ?
        //     `/${this.props.modalidade}/destaque-especial/${Utils.transliterate(this.props.codigoLabel)}`
        // :
        //     `/busca/${this.props.modalidade}/${Utils.transliterate(this.props.tipo)}/${Utils.transliterate(this.props.cidade)}`
        // );

        return (
            <div className="content-gallery">

                <div className="box-buttons">
                    <div className="container">
                        <Link 
                            to={`/busca/${this.props.modalidade}/${Utils.transliterate(this.props.tipo)}/${Utils.transliterate(this.props.cidade)}`}>
                            <img src={leftArrow} alt="Voltar"/>
                        </Link>

                        <button onClick={() => this.handleGallety(0)}>
                            <img src={photography} alt="Ver todas as fotos"/> &nbsp;&nbsp;
                            <span>{this.props.imagens.length} Fotos</span>
                        </button>
                    </div>                    
                </div>

                <div className="gallery">
                    <OwlCarousel
                        className="owl-theme"
                        loop = {false}
                        margin = {2}
                        responsive = {this.state.responsive}
                        nav = {false}
                        dots = {false}
                    >
                        {this.props.imagens.map((item, index) => (
                            <div className="item" key={index}>
                                <img src={item.imagem} alt={item.descricao} onClick={() => this.handleGallety(index)} /> 
                            </div>  
                        ))}                                         
                    </OwlCarousel>
                </div>                
                
                <ReactBnbGallery
                    show={this.state.isOpen}
                    photos={this.state.photos}
                    onClose={()=>this.setState({isOpen: false})}
                    wrap = {true}
                    opacity={.9}
                    activePhotoIndex={this.state.numberPhoto}
                    showThumbnails={false}
                />               
            </div>
        );
    }
}