import React, { Component } from 'react';
import Headline from '../../components/HeaderLine';
import CardImovel from '../../components/Card';

import Favorites from '../../helpers/Favorites';
import NoFavorites from './NoFavorites';

import './styles.css';

export default class Content extends Component {
    constructor (props) {
        super(props);
        this._favoritos = new Favorites();        
    }

    render() {
        let content;
        if (this._favoritos.getQtdFavorites() > 0) {
            content = this._favoritos.getFavorites().map(card => (
                <CardImovel key={card.id} dados={card} icon='trash' image='/images/demo/img-favorito.jpg'/>
            ))

        } else {
            content = <NoFavorites/>;
        }

        return (
            <main id='favorites-main'>                
                <Headline label="Meus imóveis favoritos" />
                <div className="container">
                    { content }
                </div>                
            </main>
        );
    }        
}