const listItems = [
    {
        label: 'Meus favoritos',
        to: '/meus-imoveis-favoritos',
        icon: 'fas fa-heart'
    },
    {
        label: 'Segunda via de boleto',
        to: process.env.REACT_APP_LOGIN,
        target: '_blank',
        icon: 'fas fa-file-invoice'
    },
    {
        label: 'Extrato de prestação de contas',
        to: process.env.REACT_APP_LOGIN,
        target: '_blank',
        icon: 'far fa-handshake'
    },
    {
        label: 'alugue ou venda seu imóvel conosco',
        to: '/cadastre-seu-imovel',
        icon: 'fas fa-bullhorn'
    },
    {
        label: 'Estacionamentos',
        to: '/gestao-de-estacionamentos',
        icon: 'fas fa-car-alt'
    }
]

export default listItems;