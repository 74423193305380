import React from "react";
import { Link } from "react-router-dom";

import Utils from './../../../../../helpers/Utils';

import "./styles.css";

const Select = ({label, list, finalidade}) => {
    return (   
        <div className="field-box">
            <span tabIndex='0'>
                {label}
                <i className="fas fa-chevron-down"></i>                                                
            </span>

            <ul className="field-list">
                {list.map((list, index) =>(
                    <li key={index} className="field-list-item">
                        <Link to={`/busca/aluguel/${Utils.transliterate(list.name)}/fortaleza/${finalidade}`}>{Utils.capitalize(list.name)}</Link>
                    </li>
                ))}                
            </ul>
        </div>   
    );
};
export default Select;