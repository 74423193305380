import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";

const CardFeaturedServices = ({label, icon, to, ...rest}) => {
    return (   
        <article>
            <Link to={{pathname: to}} {...rest} rel="noopener noreferrer"> 
                <img src={icon} alt={label} />
                <h2>{label}</h2>
            </Link>
        </article>
    );
};
export default CardFeaturedServices;