import React from 'react';
import HeaderLine from '../../../components/HeaderLine';
import OwlCarousel from 'react-owl-carousel';

import Utils from './../../../helpers/Utils';
import Card from './Card';

import './styles.css';

const SimilarProperty = ({tipo, cidade, similares, handleLoadImovel }) => {
    const [state] = React.useState({
        responsive:{
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            1200: {
                items: 3
            }
        }
    });
    return (
        <div id="similar-property">
            <div className="container">
                <HeaderLine label={`${Utils.capitalize(tipo)} semelhantes em ${cidade}`} />

                <OwlCarousel
                    className="owl-theme"
                    loop = {false}
                    responsive = {state.responsive}
                    nav = {false}
                >
                    { similares.map((imovel, index) => {
                        return (
                            <Card key={index} imovel={imovel} handleLoadImovel={handleLoadImovel} />
                        );
                    }) }

                </OwlCarousel>            
            </div>
        </div>
    );
}

export default SimilarProperty;
