import React from 'react';
import { Link } from 'react-router-dom';

import listItems from './listItems';

import './styles.css';

const Menu = () => {
    return (
        <div id="menu-box">
            <span tabIndex='0'>
                <i className="fas fa-th-list"></i> &nbsp;Menu
            </span>            
            <ul>
                <li><i className="far fa-times-circle" /> Menu</li>
                <li>
                    <Link to="/busca/aluguel/apartamento/fortaleza/residencial">
                        <i className='fas fa-key' /> 
                        <span>Imóveis para alugar</span>
                    </Link>
                </li>
                <li>
                    <Link to="/busca/venda/apartamento/fortaleza/residencial">
                        <i className='fas fa-home' />
                        <span>Imóveis à venda</span>
                        
                    </Link>
                </li>
                {listItems.map((item, index) => (
                    <li key={index}>                        
                        <Link to={{pathname:item.to}} target={item.target} rel="noopener noreferrer"> 
                            <i className={item.icon} />
                            <span>{item.label}</span>
                        </Link>
                    </li>
                ))}                                                        
            </ul>
        </div>
    );
}

export default Menu;
