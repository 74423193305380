import React from 'react';

import './styles.css';

const Topo = () => {
    return (
        <section id="company">
            <div className="company-topo">
                <h1>{process.env.REACT_APP_FULL_NAME}</h1>
                <h2>Nossa empresa</h2>
            </div>
        </section>
    );
}

export default Topo;