import React, { Component } from 'react';
import ListTypes from  './../ListTypes';
import Input from './../Input';
import api from './../../../../../services/api';
import './styles.css';
import { Select } from 'semantic-ui-react';

export default class fieldBox extends Component {
    state = {
        listImoveis: {residenciais:[], comerciais:[]},
        codigo: '',
        value: 'venda'
    }    
    
    componentDidMount(){
        this.listTiposImoveis();
    }

    listTiposImoveis =  async() => {
        await api.get('/aluguel/tiposImoveisDistintosOptions').then(
            response => {
                this.setState({
                    listImoveis: response.data.options
                });
            }
        );
    }

    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: (e.target.value !== null ? e.target.value : '')
        });
    };

    handleOnSubmit = (e) => {
        e.preventDefault();
        this.props.history.push(`detalhes/${this.state.value}/${this.state.codigo}`);  
    }   
    
    handleOnChangeFinalidade = (e) => {
        this.setState({
            value: e.target.value
        });
    };

    render() {
        return (
            <div id="search-container">
                <h2>
                    O imóvel que está procurando
                    <span>você vai encontrar aqui!</span>
                </h2>

                <div id="fields-content">                    
                    <ListTypes label='Residenciais' list={this.state.listImoveis.residenciais} finalidade='residencial'/>
                    <ListTypes label='Comerciais' list={this.state.listImoveis.comerciais} finalidade='comercial'/>
                </div>
                
                <form onSubmit={(e) => this.handleOnSubmit(e)}> 
                    <select value={this.state.value} onChange={(e) => this.handleOnChangeFinalidade(e)}>
                        <option value="venda">Comprar</option>
                        <option value="aluguel">Alugar</option>
                    </select>
                    <fieldset>                        
                        <Input 
                            type="text"
                            name="codigo"
                            text="Busca por código"
                            codigo={this.state.codigo}
                            handleOnChange={this.handleOnChange} 
                        />
                    </fieldset>
                    <button type="button" onClick={this.handleOnSubmit} >
                        <i className="fas fa-search"></i>
                    </button> 
                </form>
            </div>            
        );
    }
} 