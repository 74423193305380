import React from 'react';
import HeaderLine from '../../components/HeaderLine';
import Card from '../../components/CardInformation';

import monitor from '../../assets/images/icons/monitor.svg';
import trophy from '../../assets/images/icons/trophy.svg';
import creativity from '../../assets/images/icons/creativity.svg';

import './styles.css';

export default function Content(){
    return (
        <main>
            <section id="park-topo">
                <div className="park-wrapper">
                    <h1>Estacione na Estafor e fique tranquilo!</h1>
                    <h2>Gestão de estacionamentos</h2>                  
                </div>
            </section>

            <section id="park-content">
                <div className="container">
                    <HeaderLine label="Nossa história" />

                    <p>
                        Pioneira na administração de estacionamentos informatizados em Fortaleza, 
                        a <span>{process.env.REACT_APP_NAME}</span> iniciou suas atividades em 1993, inovando também ao 
                        ser a primeira empresa a contratar seguro para os veículos de seus clientes.
                    </p>
                    <p>
                        Logo nos seus primeiros anos, a <span>{process.env.REACT_APP_NAME}</span> já demonstrava o 
                        compromisso com a atividade desempenhada quando participou ativamente da criação do SINDEPARK/CE – 
                        Sindicado da Empresas de Estacionamento do Estado do Ceará em 1995, visando contribuir para a 
                        profissionalização e consequente melhoria dos serviços prestados pelo setor.
                    </p>
                    <p>
                        Em seu planejamento estratégico a <span>{process.env.REACT_APP_NAME}</span> incluiu plano de 
                        treinamento anual com todos seus colaboradores para que possam prestar um atendimento 
                        diferenciado, respeitando os clientes e suas necessidades.
                    </p>
                    <p>
                        Empresa genuinamente cearense que, ao longo de mais de 25 anos, construiu uma marca forte, 
                        sinônimo de tradição e seriedade, reconhecida por clientes e parceiros pela segurança, 
                        transparência e responsabilidade.
                    </p>
                    <p>
                        Atingiu em 2021 a marca de mais de 23 milhões de veículos recebidos em seus estacionamentos.
                    </p>
                    <p>
                        Além da administração de garagens e serviço de valet, a empresa oferece ainda consultoria do 
                        desenvolvimento de projetos e na implantação de garagens nas mais diversas atividades comerciais.
                    </p>                                
                </div>
            </section>

            <section id="park-benefits">
                <div className="container">
                    <HeaderLine label="Por que escolher a Estafor?" />

                    <div className="park-benefits-box">
                        <Card 
                            icon={monitor} 
                            title="Segurança" 
                            text="Equipamentos de ponta aliados a um controle eficiente de acessos e uma equipe bem 
                            treinada garantem a nossos clientes e parceiros um serviço de qualidade prestado com muita segurança. " 
                        />

                        <Card 
                            icon={trophy} 
                            title="Atendimento" 
                            text="Estamos presentes nos mais diversos estabelecimentos comercias e nossa equipe está 
                            pronta para atender às necessidades de cada cliente, seja em um shopping ou edifício comercial, 
                            seja numa clínica ou hospital, ou ainda em um serviço de vallet." 
                        />

                        <Card 
                            icon={creativity} 
                            title="Soluções Inteligentes" 
                            text="Temos a experiência necessária para desenvolver, implantar e gerir seu projeto de 
                            estacionamento, visando sempre a viabilidade do negócio e a satisfação dos clientes. " 
                        />
                    </div>
                </div>
            </section>
        </main>
    );
}