import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

export default class Topo extends Component {
     render(){
        return (
            <div id="topo-main">
                <div className="container">
                    <div className="contato">
                        <span className="icone"><i className="fa fa-phone"/> {process.env.REACT_APP_PHONE}</span>
                        | &nbsp;&nbsp;                            
                        <span className="icone">
                            <Link 
                                to={{pathname: process.env.REACT_APP_WHATSAPP_API}}                                        
                                target="_blank"
                                rel="noopener noreferrer" 
                            >
                                <i className="fa fa-whatsapp"/> &nbsp;
                                {process.env.REACT_APP_WHATSAPP}
                            </Link>                                        
                        </span>
                        | &nbsp;&nbsp;
                        <span className="icone"><i className="fa fa-envelope-o"/> {process.env.REACT_APP_EMAIL}</span>
                    </div>
                    <div className="rede-social">                                                
                        <span className="icone">
                            <Link 
                                to={{pathname: process.env.REACT_APP_INSTAGRAM}}                                        
                                target="_blank"
                                rel="noopener noreferrer" 
                            >
                                <i className="fa fa-instagram"/> &nbsp;
                                Instagram                      
                            </Link>                                        
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}
