import React from 'react';
import Breadcrumbs from './Breadcrumbs';
import ButtonsFilters from './ButtonsFilters';
import LoadingMask from "react-loadingmask";

import './styles.css';

const Topo = (props) => {    
    return (
        <div id="topo">
            <div className="container">
                <LoadingMask loading={props.loadingContent} >
                    <Breadcrumbs params={props.params} />
                    <ButtonsFilters
                        {...props}
                        getPrice={props.getPrice}
                        getComfortables={props.getComfortables}
                        handleCheckedList={props.handleCheckedList}
                        handleCheckedListCondominio={props.handleCheckedListCondominio}
                        getFilters={props.getFilters}
                        handleSelect={props.handleSelect}
                    />
                </LoadingMask>
            </div>
        </div>
    );
}
export default Topo;