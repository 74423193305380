import React from "react"
import ContentLoader from "react-content-loader"

const Loader = (props) => (
  <div className="box-loader">
    <ContentLoader
      width={1240}
      height={600}
      {...props}   
    >
      <circle cx="26" cy="50" r="7" /> 
      <circle cx="66" cy="50" r="7" /> 
      <circle cx="107" cy="50" r="7" />
      <rect x="0" y="152" rx="10" ry="10" width="800" height="350" /> 
      <rect x="840" y="153" rx="10" ry="10" width="400" height="400" /> 
      <rect x="0" y="550" rx="10" ry="10" width="800" height="20" />       
    </ContentLoader>
  </div>
)

export default Loader