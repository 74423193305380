import api from './../../services/api';
import * as constants from './constants';

export default class Services {
    busca = (modalidade, finalidade, tipo, endereco, filtros = [], page, order = 'menor-valor', limit = 10, offset = 0 ,caracSelecionados = [], caracSelecionadosCondominio = [], destaque) => {

        let currentPage = (page ? page : 1);

        let url = `${modalidade}/imoveis?limit=20&offset=${offset}&page=${currentPage}`;

        if (tipo) {
            url += `&tipo=${tipo.replace('-', ' ')}`;
        }

        if (endereco) {
            url += `&endereco=${endereco}`;
        }

        if (finalidade) {
            url += `&finalidade=${finalidade}`;
        }

        if (filtros) {
            for (let i in filtros) {
                if (
                filtros[i] === '' || filtros[i] === null || filtros[i] === undefined || filtros[i] === 0 || 
                filtros[i] === constants.PRICE_RENT_INITIAL[1] || 
                filtros[i] === constants.PRICE_SALE_INITIAL[1] ||
                filtros[i] === constants.AREA_INITIAL[1]) continue;
                url += `&${i}=${filtros[i]}`;
            }
        }

        if (caracSelecionados.length > 0) {
            let list = caracSelecionados.map(item => [
                item.value
            ]);
            url += `&caracteristicas=${list}`;
        }
        
        if (caracSelecionadosCondominio.length > 0) {   
            let list = caracSelecionadosCondominio.map(item => [
                item.value
            ]);
            url += `&caracsCondominio=${list}`;
        }

        if (destaque) {
            url += `&destaque=${destaque.replace(/-/g, ' ')}`;
        }


        return api.get(url)
            .then(response => {                
                if (!response.status === 200) {
                    throw response;
                }

                return response.data.resultado;
            })
            .catch(e => console.log(e));;
    };

    getCaracteristicasImovel = (modalidade, tipo) => {
        return api.get(`${modalidade}/caracteristicasOptions?tipo=${tipo}`)
        .then(response => {                
            if (!response.status === 200) {
                throw response;
            }

            return response.data.options;
        })
        .catch(e => console.log(e));;
    }

    getCaracteristicasCondominio = (modalidade, tipo) => {
        return api.get(`${modalidade}/caracteristicasCondominioOptions?tipo=${tipo}`)
        .then(response => {                
            if (!response.status === 200) {
                throw response;
            }
            return response.data.options;
        })
        .catch(e => console.log(e));;
    }

    getTiposImoveisOptions = (modalidade) => {
        return api.get(`${modalidade}/tiposImoveisOptions`)
        .then(response => {                
            if (!response.status === 200) {
                throw response;
            }
            return response.data.options;
        })
        .catch(e => console.log(e));;
    }

    getbuscaEndereco = (modalidade, palavra) => {
        return api.get(`${modalidade}/buscaEndereco?palavra=${palavra}`)
        .then(response => {                
            if (!response.status === 200) {
                throw response;
            }
            return response.data.resultado;
        })
        .catch(e => console.log(e));;
    }

    // solicitarInformacoesImovel = (dados) => {
    //     return fetch(`email/solicitarInformacoesImovel`, BaseServices._getRequestInfo('POST', `${JSON.stringify(dados)}`))    
    //       .then(response => {
    //         if (!response.ok) {
    //           throw response.json();          
    //         }
    
    //         return response.json();
    //     });
    // }
}
