import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import Topo from './Topo';
import Content from './Content';

export default class Company extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render(){
        return (
            <React.Fragment>
                <Helmet title={`Nossa Empresa | ${process.env.REACT_APP_FULL_NAME}`} />
                <Header />
                <main>                    
                    <Topo />
                    <Content />
                </main>
                <Footer />                
            </React.Fragment>
        );
    }
}