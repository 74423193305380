import React from 'react';
import Utils from './../../../../../../helpers/Utils';

import './styles.css';

export default function Item (props) {

    return (
        <div className="comfortable-item">                        
            <span>{props.label}</span>
            <span className="comfortable-counter">                
                <button
                    type='button'
                    onClick={()=>props.decrement(Utils.transliterate(props.label === 'Banheiros' ? 'wc' : props.label))}
                    disabled={props.counter === 0 ? true : false}>
                    -
                </button>
                <span> {props.counter} + </span>
                <button
                    type='button'
                    onClick={()=>props.increment(Utils.transliterate(props.label === 'Banheiros' ? 'wc' : props.label))}
                    disabled={props.counter === 5 ? true : false}>
                    +
                </button>

            </span>
        </div>
    );
}