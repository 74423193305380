import React from 'react';

import './styles.css';

const Card = ({icon, title, text}) => {   
    return (
        <article className="card-benefits">
            <img src={icon} alt="Vantagens"/>

            <h2> {title} </h2>
            <p> {text} </p>

        </article>
    );
}

export default Card;