import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Favorites from '../../helpers/Favorites';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Utils from '../../helpers/Utils';
import Button from '../../components/Button';
import Loader from './Loader';

import bed from '../../assets/images/icons/bed.svg';
import bathtub from '../../assets/images/icons/bathtub.svg';
import garage from '../../assets/images/icons/garage.svg';
import environment from '../../assets/images/icons/environment.svg';

import './styles.css';

export default class Card extends Component {
    constructor(props){
        super(props);
        this._favorites = new Favorites();

        this.state = {        
            responsive:{}
        };
    }

    componentDidMount() {
        this.setState({ responsive:{0: {items: 1}} });
    }

    getSlug(tipo, quartos, suites, bairro, cidade, estado) {
        let dormitorios_total = quartos + suites;
        let slug = '';

        if (dormitorios_total === 1) {
            slug = `${tipo}-${dormitorios_total}-dormitorio-no-bairro-${bairro}-em-${cidade}-${estado}`;
        } else if (dormitorios_total > 1) {
            slug = `${tipo}-${dormitorios_total}-dormitorios-no-bairro-${bairro}-em-${cidade}-${estado}`;
        } else {
            slug = `${tipo}-no-bairro-${bairro}-${cidade}-${estado}`;
        }

        return Utils.transliterate(slug);
    }

    render() {        
        const link = `/detalhes/${this.props.dados.modalidade}/${this.props.dados.id}/${this.getSlug(this.props.dados.tipo, this.props.dados.quartos, this.props.dados.suites, this.props.dados.bairro, this.props.dados.cidade, this.props.dados.estado)}`;

        let caracteristicaPorFinaldiade = '';
        if (this.props.dados.finalidade === 'Residencial') {
            caracteristicaPorFinaldiade =
            <li>
                <img src={bed} alt="Dormitórios"/>
                <span> {this.props.dados.dormitorios_total} dormitorio{this.props.dados.dormitorios_total > 1 ? 's' : ''} </span>
            </li>
        } else {
            caracteristicaPorFinaldiade =
            <li>
                <img src={environment} alt="Ambientes"/>
                <span>{this.props.dados.ambientes} ambiente{this.props.dados.ambientes > 1 ? 's' : ''}</span>
            </li>
        }

        return (
            <div id={`card-content-${this.props.dados.id}`} className="card-content">
                <div className="card-image">
                        <Loader />
                        <OwlCarousel
                            className="owl-theme"
                            loop
                            margin = {10}
                            responsive = {this.state.responsive}
                            nav
                            dots = {false}
                        >
                            {this.props.dados.imagens.map((item, index) => (
                                <div key={index} className="item">
                                    <Link to={link}>
                                        <img src={item.imagem} alt={item.descricao}/>
                                    </Link>
                                </div>
                            ))}
                        </OwlCarousel>                        
                   
                </div>                           

                <div className="card-wrapper">
                    <div className="card-code">Cód: {this.props.dados.id}</div>
                    
                    <i 
                        id={`icon_${this.props.dados.id}`}
                        onClick={() => this._favorites.update(this.props.dados)}
                        className={this._favorites.getIcons(this.props.dados.id, this.props.icon)}
                    />

                    <h3 className="card-wrapper-type">
                        <Link to={link}> 
                            {this.props.dados.titulo}
                        </Link>
                    </h3>
                    <span>{Utils.ucWords(this.props.dados.bairro)}, {this.props.dados.cidade} - {this.props.dados.estado}</span>                           

                    <ul className="card-account">
                        <li><span>{this.props.dados.area_total}m²</span>área</li>
                        <li><span>R$ {this.props.dados.valor.replace(',00','')}</span>{this.props.dados.modalidade}</li>
                    </ul>

                    <ul className="card-feature">
                        {caracteristicaPorFinaldiade}

                        <li>
                            <img src={bathtub} alt="Banheiros"/>
                            <span> {this.props.dados.wc_total} banheiro{this.props.dados.wc_total > 1 ? 's' : ''}</span>
                        </li>
                        <li>
                            <img src={garage} alt="Vagas de garagem"/>
                            <span>{this.props.dados.vagas} vaga{this.props.dados.vagas > 1 ? 's' : ''}</span>
                        </li>
                    </ul>

                    <div className="card-buttons">
                        <Button
                            to={link}
                            label="Mais detalhes" 
                            color="primary"
                        />
                    </div>                            
                </div>
            </div>
        );
    }
}