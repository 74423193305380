import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';
import Swal from 'sweetalert2';
import LoadingMask from "react-loadingmask";
import { Form, Input, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import api from '../../../../services/api';

const Contato = (props) => {
  const {
    className
  } = props;

  const [state, setState] = useState({
      nome: '',
      email: '',
      celular: '',
      mensagem: `Olá, gostaria de agendar uma visita para o imóvel no bairro ${props.bairro}, ${props.cidade} - ${props.estado}. O código do imóvel é ${props.codigo}. Aguardo retorno.`
  });

  const [loading, setLoading] = useState(false);

  const handleOnChange = (e) => {
    setState({
        ...state,
        [e.target.name]: e.target.value
    });
  }

  const clear = () => {
    setState({
      nome: '',
      email: '',
      celular: '',
      mensagem: `Olá, gostaria de agendar uma visita para o imóvel no bairro ${props.bairro}, ${props.cidade} - ${props.estado}. O código do imóvel é ${props.codigo}. Aguardo retorno.`  
    })
  }

  const handleSubmitAgendamento = (event) => {
    event.preventDefault();
    setLoading(true);

    let dados =
    {
        "codigo": props.codigo,
        "modalidade": props.modalidade,
        "link": window.location.href,
        "titulo": props.titulo,
        "nome": state.nome,
        "email": state.email,
        "celular": state.celular,
        "mensagem": state.mensagem
    }

    api.post('/email/agendamentoDeVisita', dados).then( response => {
      setLoading(false);

      if (response.data.code === 1) {
          getAlert('success', 'Dados enviado com sucesso!');
          props.toggle();
          clear();
      }

      if (response.data.code === 2) {
          getAlert('error', 'Ooops! Erro no envio dos dados.');
      }

    });
  }
  
  const getAlert = (icon, message) => {
    return Swal.fire({
      position: 'center',
      icon: icon,
      title: message,
      showConfirmButton: false,
      timer: 1500
    });
  }

  return (
    <Modal isOpen={props.isOpen} toggle={()=>props.toggle()} className={className}>
        <ModalHeader toggle={()=>props.toggle()}>Agendar visita</ModalHeader>
        <ModalBody>
          <LoadingMask loading={loading} >
            
            <Form className="form-agendamento" onSubmit={event => handleSubmitAgendamento(event)}>
              <Input 
                  name="nome"
                  type="text"
                  placeholder="Seu nome"
                  required={true}
                  onChange={(event)=>handleOnChange(event)}
                  value={state.nome} />

              <Input
                  name="email"
                  type="email"
                  placeholder="Seu e-mail"
                  required={true}
                  onChange={(event)=>handleOnChange(event)}
                  value={state.email} />

              <MaskedInput
                  className="form-control"
                  name='celular'
                  mask={['(', /[1-9]/, /\d/, ')', ' ',/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  placeholder="Seu celular"                            
                  guide={false}
                  required={true}
                  onChange={(event)=>handleOnChange(event)}
                  value={state.celular} />

              <Input
                  name="mensagem"
                  type="textarea"
                  required={true}
                  rows="3"
                  onChange={(event)=>handleOnChange(event)}
                  value={state.mensagem} />

              <Button type="submit">Agendar visita</Button>       
            </Form>

            </LoadingMask>
        </ModalBody>
    </Modal>
  );
}

export default Contato;