import React, { Component } from 'react';
import Select2 from 'react-select2-wrapper';
import Dropdown from 'rc-dropdown';
import Switch from '@material-ui/core/Switch';
import Button from './../../../../components/Button';
import Comfortable from './Comfortable';
import Price from './Price';
import Drawer from './Drawer';

import Utils from '../../../../helpers/Utils';

import './styles.css';

export default class ButtonsFilters extends Component {
    state = {        
        checkedMap: false,
        priceView: false,
        comfortableView: false
    }

    handleChangeMap = (event) => {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
        if (event.target.checked) {
            document.querySelector('#result-card').classList.add('viewe-map');
            document.querySelector('#result-map').classList.add('viewe-map');
        } else {
            document.querySelector('#result-card').classList.remove('viewe-map');
            document.querySelector('#result-map').classList.remove('viewe-map');
        }
        
    };

    toggle = (item) => {
        this.setState({ [item]: !this.state[item] });
    }

    openDrawer = () => {
        document.querySelector('#drawer').classList.remove('no-drawer');
        document.querySelector('#drawer-overlay').classList.remove('no-overlay');
    }
    
    render() {
        const valorInicial = Number(this.props.filtros.valorInicial);
        const valorFinal = Number(this.props.filtros.valorFinal);
        const areaInicial = Number(this.props.filtros.areaInicial);
        const areaFinal = Number(this.props.filtros.areaFinal);

        const preco = (
            <Price
                modalidade={this.props.modalidade}
                preco={valorInicial > 0 || valorFinal > 0 ? [valorInicial, valorFinal] : false}
                handleClose={() => this.toggle('priceView')}
                handleChangePrice={this.handleChangePrice}
                clearPrice={this.clearPrice}
                getPrice={this.props.getPrice}
            />
        );

        const comodos = (
            <Comfortable
                filtros={this.props.filtros}
                handleClose={() => this.toggle('comfortableView')}
                clearComfortables={this.clearComfortables}
                getComfortables={this.props.getComfortables}
            />
        );

        const listBairros = this.props.bairros.map((bairro) => (
            { text: Utils.ucWords(bairro.text), id: Utils.transliterate(bairro.id) }
        ));

        const listCidades = this.props.cidades.map((cidade) => (
                { text: Utils.ucWords(cidade.text), id: Utils.transliterate(cidade.id) }
        ));

        return (
            <div id="search-filters-topo">
                <div className="col-left">                
                    <form className="search-location">                        
                        <Select2                            
                            name="endereco"
                            className="form-control"
                            data={[
                                { text: 'Cidades',
                                    children: listCidades,
                                },
                                { text: 'Bairros',
                                    children: listBairros,
                                }
                            ]}
                            options={{
                                placeholder: 'Selecione um bairro ou cidade',
                            }}
                            value={this.props.endereco}
                            onSelect={(e) => this.props.handleSelect(e)}
                            required={true}
                        />                        
                    </form>

                    <div className="preco-content">
                        <Dropdown
                            trigger={['click']}
                            overlay={preco}
                            animation="slide-up"
                            visible={this.state.priceView}
                            onVisibleChange={() => this.toggle('priceView')}
                        >
                            <button className='secondary'>Preço</button>
                        </Dropdown>
                    </div>

                    <div className="comfortable-content">
                        <Dropdown
                            trigger={['click']}
                            overlay={comodos}
                            animation="slide-up"
                            visible={this.state.comfortableView}
                            onVisibleChange={() => this.toggle('comfortableView')}
                        >
                            <button className='secondary'>Cômodos</button>
                        </Dropdown>
                    </div>

                    <Button label="Mais filtros" color="secondary" onClick={this.openDrawer} />                    
                    <Drawer
                        modalidade={this.props.modalidade}
                        preco={valorInicial > 0 || valorFinal > 0 ? [valorInicial, valorFinal] : false}
                        area={areaInicial > 0 || areaFinal > 0 ? [areaInicial, areaFinal] : false}
                        filtros={this.props.filtros}
                        tipo={this.props.tipo}
                        endereco={this.props.endereco}
                        listTipos={this.props.tipos}
                        listBairros={this.props.bairros}
                        listCidades={this.props.cidades}
                        caracteristicas={this.props.caracteristicas}
                        caracsCondominio={this.props.caracsCondominio}
                        caracSelecionados={this.props.caracSelecionados}
                        caracSelecionadosCondominio={this.props.caracSelecionadosCondominio}
                        handleCheckedList={this.props.handleCheckedList}
                        handleCheckedListCondominio={this.props.handleCheckedListCondominio}
                        getFilters={this.props.getFilters} 
                    />                               
                </div>

                <div className="col-right">
                    <div className="button-filters-box">
                        <Switch
                            id="checkedMap"
                            checked={this.state.checkedMap}
                            onChange={this.handleChangeMap}
                            color="primary"
                            name="checkedMap"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        <label htmlFor="checkedMap">Ver no mapa</label>
                    </div>                
                </div>
            </div>
        );
    }
}