import React, { Fragment } from 'react';
import HeaderLine from '../../../components/HeaderLine';

import positive from '../../../assets/images/icons/positive.svg';

import './styles.css';

const Content = () => {
    return (
        <Fragment>
        <section id="company-mission-vision">
            <div className="container">
                <article>
                    <h2>Missão</h2>
                    <p>
                        Administrar estacionamentos e locações imobiliárias maximizando a satisfação de clientes, 
                        colaboradores e acionistas.
                    </p>
                </article>
                <article>
                    <h2>Visão</h2>
                    <p>
                        Ser reconhecida pela excelência na intermediação e administração de locações imobiliárias 
                        no Estado do Ceará.
                    </p>
                </article>
            </div>
        </section>
        <section id="company-content">
            <div className="container">
                <HeaderLine label="Sobre nós" />

                <p>
                    Empresa fundada em 1989 para prestar serviços de administração de imóveis residenciais e comerciais 
                    no estado do Ceará, intermediando locações com responsabilidade e segurança.
                </p>
                <p>
                    Em 1993, diversificou suas atividades passando a desenvolver e administrar estacionamentos de veículos, 
                    sendo a pioneira na profissionalização da atividade em Fortaleza, implantando sistemas de controles 
                    informatizados e contratação de seguro ainda naquele mesmo ano.
                </p>
                <p>
                    Atualmente consolidada no mercado imobiliário cearense, sinônimo de solidez e confiança, atua nas 
                    áreas de locação e administração de imóveis, compra e venda, gestão de estacionamento entre outras.
                </p>
                <p>
                    Temos ainda o orgulho de ser uma empresa familiar, associada a Associação Cearense de Administradoras 
                    de Imóveis do Ceará – AADIC e do Sindicado das Empresas de Garagens e Estacionamentos do Ceará – SINDEPARK/CE.
                </p>
                <p>
                    Venha nos fazer uma visita e ser nosso cliente. 
                </p>
                                           
            </div>
        </section>
        <section id="company-values">
            <div className="container">
                <HeaderLine label="Nossos valores" />
                <div className="content">
                    <img src={positive} alt="Nossos valores" />
                    <ul>
                    <li>
                        <i className="fas fa-check-double" />
                        <span>Confiança na Justiça e Onipotência Divina</span>
                    </li>
                    <li>
                        <i className="fas fa-check-double" />
                        <span>Respeito ao Cliente</span>
                    </li>
                    <li>
                        <i className="fas fa-check-double" />
                        <span>Capacitação da Equipe</span>
                    </li>
                    <li>
                        <i className="fas fa-check-double" />
                        <span>Transparência</span>
                    </li>
                    <li>
                        <i className="fas fa-check-double" />
                        <span>Responsabilidade Social</span>
                    </li>
                </ul>
                </div>
            </div>
        </section>
        </Fragment>
    );
}

export default Content;