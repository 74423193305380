import React from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

const CardEspecialProperties = ({to, label, highlight, image}) => {
    return (
        <article className="card-box">
            <Link to={to}>
                <div>
                    {label}&nbsp;
                    <span>{highlight}</span>
                </div>
            </Link>
            <img src={image} alt={`${label} ${highlight}`}/>
        </article>
    );
}

export default CardEspecialProperties;