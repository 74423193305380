import React, { useState } from 'react';
import EspecialFeatures from './EspecialFeatures';
import Title from '../../../components/Title';
import Agendamento from './Agendamento';

import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import Leaflet from 'leaflet';
import 'leaflet.fullscreen/Control.FullScreen';

import location from '../../../assets/images/icons/location.svg';

import './styles.css';

const markerIcon = Leaflet.icon({
    iconUrl: location,
    iconSize:[40, 40],
    iconAnchor:[20, 40],
    popupAnchor:[140, 18]
});


const ContentDetails = ({ imovel }) => {

    const [viewMap, setViewMap] = useState(false);

    const handleMap = () => { setViewMap(true) };

    return(
        <div id="content-details">
            <div className="container">

                <div className="content-left">

                    <div className="block-wrapper">                    
                        <EspecialFeatures imovel={imovel} />

                        <div className="block-details">
                            <h1>{ imovel.tituloDetalhes }</h1>
                            <p>
                                { imovel.descricao }
                            </p>
                        </div>

                        <div className="block-list-features">                        
                            <Title label="Detalhes do imóvel"/>

                            <ul>
                                {imovel.caracteristicas.map((item, index) => (
                                    item.nome.indexOf('Dormitorios Total') === -1 &&
                                    item.nome.indexOf('Wc Total') === -1 ? 
                                    <li key={index}><i className="fas fa-check" /> {item.nome}</li> : ''                                    
                                ))}                            
                            </ul>
                        </div>
                        
                        { imovel.condominio.length > 0 && 
                            <div className="block-list-features">
                                <Title label="Detalhes do edifício" />
                                <ul>
                                    {imovel.condominio.map((item, index) => (
                                        <li key={index}><i className="fas fa-check" /> {item.nome} </li>
                                    ))} 
                                </ul>
                            </div>
                        }
                    </div>

                    <div className="block-location">
                        <Title label="Localização do imóvel" />
                        <i className="fas fa-map-marker-alt" />
                        <h3>{ imovel.bairro }, { imovel.cidade } - { imovel.estado }</h3>
                        
                        <div className="block-map">
                            <div className="content">                                    
                                <span onClick={()=>handleMap()}>Ver no mapa</span>
                            </div>

                            {viewMap ? 
                                <Map 
                                    center={[imovel.latitude, imovel.longitude]}
                                    zoom={15}
                                    fullscreenControl={true}
                                    scrollWheelZoom={false}
                                    style={{ width: '100%', height: '100%' }}
                                >
                                    <TileLayer
                                        url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
                                    />
    
                                    <Marker 
                                        position={[imovel.latitude, imovel.longitude]}
                                        icon={markerIcon}
                                    >
                                        <Popup closeButton={false} maxWidth={210} className="popup-map">
                                            { imovel.endereco }, { imovel.bairro } - &nbsp;
                                            { imovel.cidade } - {imovel.bairro}
                                        </Popup>
                                    </Marker>
    
                                </Map> : ''}                            
                        </div>

                    </div>

                </div>

                <div className="content-right">
                    
                    <Agendamento imovel={imovel}/>

                </div>
            </div>
        </div>
    );
}

export default ContentDetails;