import React from 'react';
import Routes from './routes';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet.fullscreen/Control.FullScreen.css';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "react-loadingmask/dist/react-loadingmask.css";
import 'rc-dropdown/assets/index.css';
import 'react-select2-wrapper/css/select2.css';

import './assets/css/global.css';

function App() {
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
