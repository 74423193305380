import React from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

const Contacts = () => {
    return (
        <div id="footer-box-contacts">
            <h2>Contato</h2>
            <ul>
                <li>
                    <i className="far fa-clock"></i>
                    <span>Segunda a sexta: 8h às 18h | Sábado: 8h às 12h</span>
                </li>
                <li>
                    <i className="fas fa-map-marker-alt"></i>
                    <span>{process.env.REACT_APP_ADDRESS}</span>
                </li>
                <li>
                    <i className="far fa-envelope"></i>
                    <span>{process.env.REACT_APP_EMAIL}</span>
                </li>
                <li>
                    <i className="fas fa-phone-alt"></i>
                    <span>{process.env.REACT_APP_PHONE}</span>
                </li>
                <li>
                    <i className="fab fa-whatsapp"></i>
                    <Link 
                        to={{pathname: process.env.REACT_APP_WHATSAPP_API}}
                        target="_blank"
                        rel="noopener noreferrer" > 
                        {process.env.REACT_APP_WHATSAPP}
                    </Link>
                </li>
            </ul>
        </div>
    );
}

export default Contacts;