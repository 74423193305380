import React from "react";
import "./styles.css";

const Input = (props) => {
  return (
    <div className="home-input-block">
      <input 
        type="text" 
        name={props.name} 
        placeholder={props.text} 
        value={props.codigo}
        onChange={(e) => props.handleOnChange(e)}
        required
      />
    </div>
  );
};

export default Input;